import * as React from "react"
import useCategories from "../../hooks/use-categories"
import CategoryCard from "./category-card"

const CategoryBLock = () => {
  const categories = useCategories().getCategories();
  return(
    <section className="container pt-3 pb-5">
      <h2 className="pt-5 pb-1 font-monserrat fs-3 text-shadow">Categorías de productos</h2>
      <p className="font-monserrat">Contamos con una variedad de productos agrupados en diferentes categorías.</p>
      <div className="row justify-content-center mb-5 animate__animated animate__fadeIn animate_slow">
        { categories.map(category => (
          <article className="col-12 col-sm-6 col-lg-4 col-xl-3" key={category.id}>
            <CategoryCard category={category} />
          </article>
          ))
        }
      </div>
    </section>
  )
}

export default CategoryBLock