import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Card = styled.div`
  margin-bottom: 2rem;
  position: relative;
  a {
    text-decoration: none;
    h3 {
      color: #ffffff;
      font-weight: 500;
      left: 5%;
      position: absolute;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
      top: 75%;
    }
    h3:before {
      content: " ";
      width: 35%;
      height: 4px;
      background-color: #ffcc33;
      position: absolute;
      bottom: -7px;
      transition: width 150ms;
    }
  }
  img {
    width: 100%;
    height: auto;
    &:hover {
      filter: blur(1px);
      -webkit-filter: blur(1px);
    }
  }

  &:hover h3:before {
    width: 100%;
  }
`

const CategoryCard = ({ category }) => {
  const {name, image, slug} = category
  return(
    <Card>
      <Link to={`/categoria/${slug}`} className="animate__animated animate__fadeIn animate__slow">
        <img src={image.url} alt={name} />
        <h3 className="font-monserrat fs-5 fs-sm-6 fs-xl-4 text-shadow">{name}</h3>
      </Link>
    </Card>
  )
}

export default CategoryCard